export enum B2SPCustomFieldTypeEnum {
  Text = 0,
  Number = 1,
  Date = 2,
  TextArea = 3,
  Logical = 4,
  List = 5,
  IntegrationList = 6,
  SQL = 7,
  Attachment = 8,
}
