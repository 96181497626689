export default class SalesForceInfosModel {
  id!: number;

  destino!: number;

  tipo!: number;

  descricao!: string;

  ordem!: number;

  valorPadrao!: string;

  valorPadraoDataAtual!: boolean;

  valoresListaCodigo!: Array<string>;

  valoresListaDescricao!: Array<string>;

  decimais!: number;

  vigenciaDe!: Date;

  vigenciaAte!: Date;

  obrigatorio!: boolean;

  somenteLeitura!: boolean;

  mostrarCampoTela!: boolean;

  integracaoFixa!: number;

  tabelaGravacaoERP!: string;

  campoGravacaoERP!: string;

  flagPermiteRecadastro!: boolean;

  flagPermiteVariosAnexos!: boolean;

  tituloPanelSeparado!: string;

  tituloGuiaSeparada!: string;

  flagMostrarCamposPainelSeparado!: boolean;

  flagMostrarCampoGuiaSeparada!: boolean;

  flagMostrarDigitacaoPedidos!: boolean;

  podeSelecionar!: boolean;

  valor!: string;

  dataUltimaAlteracao!: Date;

  codCliente!: string;

  uuid!: string;

  flagExcluido!: boolean;

  flagAlterado!: boolean;
}
