





























import { Vue, Component, InjectReactive } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import DatePickerField from '@/components/date-picker-field.vue';
import B2SPSalesForceInformation from '@/components/b2sp/sales-force-information.vue';
import RouterService from '@/services/router.service';
import ClientService from '@/services/crm/client.service';
import ClientModel from '@/models/crm/client.model';
import GroupedSalesForceInfosModel from '@/models/b2sp/grouped-sales-force-infos.model';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import { InjectionIdEnum } from '@/enums/injection-id.enum';

@Component({
  components: {
    DatePickerField,
    B2SPSalesForceInformation,
  },
})
export default class CrmSalesForceInformations extends Vue {
  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @inject(InjectionIdEnum.CrmClientService)
  private clientService!: ClientService;

  @InjectReactive('activeClient') readonly activeClient!: ClientModel;

  @InjectReactive('clientType') readonly clientType!: ClientTypeEnum;

  salesForceInfos: Array<GroupedSalesForceInfosModel> = [];

  noGroup = 'SEM_AGRUPAMENTO';

  async mounted(): Promise<void> {
    await this.getCustomFieldValues();
  }

  get viewTitle(): string {
    return `${this.$t('crm.navigation.dashboard', { clientType: this.$t(`crm.${this.clientType}`) })} - 
      ${this.$t('crm.view.salesForceAdditionalInfo.title')}`.toString();
  }

  get viewInfo(): string {
    return `${this.$t('crm.view.salesForceAdditionalInfo.info')}`.toString();
  }

  notGrouped(group: string): boolean {
    return group === this.noGroup;
  }

  async getCustomFieldValues(): Promise<void> {
    this.salesForceInfos = await this.clientService.getSalesForceInfos(await this.getClientIdFromRoute());
  }

  private async getClientIdFromRoute(): Promise<string> {
    if (this.clientType === ClientTypeEnum.Prospect) {
      const currentRoute = this.routerService.route();
      return currentRoute.params && currentRoute.params.clientId;
    }
    if (!this.activeClient) {
      const activeClient = await this.clientService
        .get(this.routerService.route().params.clientId, this.clientType)
        .then((cliente) => cliente);
      if (activeClient.type) {
        return activeClient.type.toString().toLowerCase() === ClientTypeEnum.Client
          ? activeClient.cnpjCpf
          : activeClient.codCliente;
      }
    }
    return this.activeClient.cnpjCpf;
  }
}
